<template>
  <div class="dialog-container">
    <el-dialog
      :visible.sync="show"
      class="all-dialog"
      @open="openDialog"
      :before-close="closeDialog"
      center
      append-to-body
      height="484px"
      width="339px">
      <div class="dialog-wrap">
        <i class="el-icon-warning"></i>
        <p class="system-tip">系统提示</p>
        <p>{{$t(`confirmDialog_type${type}`)}}</p>
        <div class="" v-if="`confirmDialog_other${type}`">{{$t(`confirmDialog_other${type}`)}}</div>
      </div>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import mixin from '@/mixins/dialogMixin'
import { debounce } from "lodash";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    type: {
      required: true,
      type: Number,
      default: 1, // 类型，1为删除确认，2是确认重置密码，3是确认批量审核通过，4是确认批量审核不通过，5是更改预约日期，如果有需要，在这里增加
    },
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent: debounce(function () {
      // 这里写业务需求
      // 因为这里是公用的组件，没办法判定说业务处理，我们把事件传到父组件，由父组件处理
      this.$emit('sureDialog')
      console.log(`我执行了点确定的业务需求`)
      // 调用关闭方法，不要再写一次
    }, 500),
  },
}
</script>
<style lang="less" scoped>
@import url('../../src/assets/styles/mixin.scoped.less');
.dialog-wrap{
  display: grid;
  justify-content: center;
  .system-tip {
    color:rgba(36,45,65,1);
    font-size:18px;
    font-weight:500;
    text-align: center;
    margin-bottom: 20px;
  }
  .el-icon-warning{
    font-size: 50px;
    margin: 0 auto;
    color: @confirmDialog-icon-color;
  }
  p{
    line-height: 40px;
    text-align: center;
  }
}
</style>
<style lang="less">
.all-dialog {
  .el-dialog__header {
    border-bottom: 0 !important;
  }
  .el-dialog__footer {
    margin: 30px;
  }
  .el-dialog__headerbtn {
    display: none !important;
  }
}
</style>

/*
 * @Author: zhongtao
 * @LastEditors: zhongtao
 */
/**
 * 数组按每相key值排序
 * @param {*} key 数组项-对象里面的key
 * @param {*} desc 默认降序
 */

/* eslint-disable */
export function compare(key, desc) {
  //key:  用于排序的数组的key值
  //desc： 布尔值，为true是升序排序，false是降序排序
  return function(a, b) {
    let  value1 = a[key];
    let  value2 = b[key];
    if (desc == true) {
      // 升序排列
      return value1 - value2;
    } else {
      // 降序排列
      return value2 - value1;
    }
  };
}

export function fomatFloat(num, n) {
  var f = parseFloat(num);
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(num * Math.pow(10, n)) / Math.pow(10, n); // n 幂
  var s = f.toString();
  var rs = s.indexOf(".");
  //判定如果是整数，增加小数点再补0
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + n) {
    s += "0";
  }
  return s;
}
// 字典值转换, value转换值, list匹配列表, key匹配的key, name返回的对应值key, flag不传或者为0表示value是字符串单个,为1表示字符串多个或者数组
export function changeInfo(value, list = [], key, name, flag = 0) {
  let result = ''
  if (flag === 1) {
    let tempArr = []
    if ((typeof value).toLowerCase() === 'string') {
      tempArr = value.split(',')
    } else {
      tempArr = value
    }
    const resultArrOne = list.filter((item) => {
      return tempArr.includes(item[key])
    })
    const resultArrTwo = resultArrOne.map((item) => {
      return item[name]
    })
    result = resultArrTwo.join(',')
  } else {
    list.forEach((item) => {
      if (value === item[key]) {
        result = item[name]
      }
    })
  }
  return result
}

 // 将数组按照某一类值,归类成二维数组
 export function groupBy(array, f) {
  var groups = {}
  array.forEach(function(o) {
    var group = JSON.stringify(f(o))
    groups[group] = groups[group] || []
    groups[group].push(o)
  })
  return Object.keys(groups).map(function(group) {
    return groups[group]
  })
}

/*
 * @Author: genwai
 * @Date: 2020-05-19 14:54:36
 * @LastEditTime: 2020-06-12 15:42:22
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \onesports-cloud-view-new\src\views\venueMana\course\dialog\addDialogClass.js
 */
/* eslint-disable */

class searchFrom {
  constructor(type) {
    if (type === 'form') {
      this.name = ''
      this.state = ''
    } else if (type === 'rule') {
      this.name = [
        {
          required: true,
          message: '标签名称不能为空',
          trigger: 'blur',
        },
      ]
      this.state = [
        {
          required: true,
          message: '请选择状态',
          trigger: ['blur', 'change'],
        },
      ]
    }
  }
}
export default searchFrom

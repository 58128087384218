<template>
  <div class="dialog-container">
    <el-dialog
      :title="`${isEdit ? $t('edit') : $t('add')}`"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="500px"
    >
      <el-form ref="form" :model="form" label-width="100px" :rules="rules">
        <el-row>
          <el-col :span="24">
            <el-form-item label="标签名称" prop="name">
              <el-input
                clearable
                placeholder="最多输入8个字"
                v-model="form.name"
                :maxlength="8"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="启用状态" prop="state">
              <el-select v-model="form.state" style="width: 100%">
                <el-option
                  v-for="item in statusList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="sureEvent">{{
          $t("sure")
        }}</el-button>
        <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import AddDialogClass from "./addDialogClass";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isEdit: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    statusList: {
      required: true,
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      flag: false,
      form: new AddDialogClass("form"), // 实例化一个表单的变量
      rules: new AddDialogClass("rule", this.Language), // 实例化一个表单的规则
      apis,
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "TOKEN"]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      this.$refs.form.resetFields();
      this.form = new AddDialogClass("form");
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.flag) {
            return;
          }
          this.flag = true;
          let reqUrl = ''
          if (this.isEdit) {
            reqUrl = apis.updateProductTag
          } else {
            reqUrl = apis.addProductTag
          }
          console.log(reqUrl);
          this.$http.post(reqUrl, this.form).then((res) => {
            if (res.data.code === 0) {
              // 调用关闭方法，不要再写一次
              this.$message.success('操作成功')
              this.flag = false;
              this.closeDialog();
            } else {
              this.flag = false;
            }
          });
        }
      });
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data) {
      // 在这里获取回显的数据
      // this.$http.get(`${apis.getProductTagById}?id=${data.id}`).then((res) => {
      this.$http.get(apis.getProductTagById, { params: { id: data.id } }).then((res) => {
        if (res.data.code === 0) {
          this.form = { ...this.form, ...res.data.data };
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.image-upload {
  width: 50px;
  height: 50px;
}
.upload-images-tip {
  color: rgba(168, 168, 168, 1);
  font-size: 12px;
  margin-top: -15px;
}
.image-wrap {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  position: relative;
  float: left;
  .images-close {
    position: absolute;
    right: -7px;
    top: -7px;
    width: 14px;
    height: 14px;
    cursor: pointer;
  }
}
</style>
<style lang="less">
@import "../../../../assets/styles/mixin.less";
</style>
